import React from "react";
import { Tabs, TabList, TabPanel } from "react-tabs";

// import Home from "../../components/Home";
// import About from "../../components/About";
// import News from "../../components/News";
// import Contact from "../../components/Contact";
import Producer from "../../components/Producer";

const HomeDark = () => {
	document.body.classList.add("dark");

	// const [_, setNewsDetails] = useState({
	// 	visible: false,
	// 	data: [],
	// });

	return (
		<>
			<Tabs>
				<TabList>
					{/* START LEFT MENU CONTENT */}
					<div className="leftpart" style={{ paddingRight: "3rem" }}>
						<div className="leftpart_inner">
							<div className="logo">
								{/* <Link className="navbar-brand" to="/"> */}
								{/* <img src="/assets/img/logo/dark.png" alt="brand" /> */}
								<h5 className="tokyo_tm_title" style={{ marginBottom: "2rem" }}>
									IBRAHIM AZAR
								</h5>
								{/* </Link> */}
							</div>
							<div className="sidebar-info">
								<p>
									Highly experienced communications and marketing professional with over 15 years of experience in developing and
									delivering strong strategic communications and marketing programs.
									<br />
									Ibrahim has worked directly with leadership teams, large organizations (Governmental & Non-Governmental), managing
									expectations and securing support
								</p>
								<div className="sidebar-contactInfo">
									<div>
										<a href="mailto:Ibraheem.azar@gmail.com">
											<img src="/assets/img/mail.png" alt="mail icon" height="15" />
											<span>Ibraheem.azar@gmail.com</span>
										</a>
									</div>
									<div>
										<a href="tel:+966 53 523 5321">
											<img src="/assets/img/telephone.png" alt="mail icon" height="15" />
											<span>+966 53 523 5321</span>
										</a>
									</div>
								</div>
							</div>
							{/* END MENU */}
							{/* <div className="copyright">
								<p>
									&copy; All Rights reserved to
									<a href="https://brainspace.sa/" target="_blank" rel="noreferrer">
										Brainspace
									</a>
								</p>
							</div> */}
							{/* END COPYRIGHT */}
						</div>
					</div>
					{/* END LEFT MENU CONTENT */}
				</TabList>
				{/* END SIDEBAR TABLIST */}

				{/* START RIGHT PART CONTENT */}
				<div className="rightpart">
					<div className="rightpart_in">
						<div className="tokyo_tm_section">
							{/* <TabPanel>
								<div className="" style={{ margin: "0 5%" }}>
									<div data-aos="fade-right" data-aos-duration="1200">
										<Home />
									</div>
								</div>
							</TabPanel>

							<TabPanel>
								<div className="container">
									<div data-aos="fade-right" data-aos-duration="1200" data-aos-delay="100">
										<About />
									</div>
								</div>
							</TabPanel> */}
							<TabPanel>
								<div className="producer-container container" style={{ padding: "0 1rem" }}>
									<div data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
										<Producer />
									</div>
								</div>
							</TabPanel>

							{/* <TabPanel>
								<div className="container">
									<News setNewsDetails={(data) => setNewsDetails(data)} newsDetails={newsDetails} />
								</div>
							</TabPanel>

							<TabPanel>
								<div className="container">
									<div data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
										<Contact />
									</div>
								</div>
							</TabPanel> */}
						</div>
					</div>
				</div>
				{/* END RIGHT PART CONTENT */}
			</Tabs>
			{/* END TABS */}
		</>
	);
};

export default HomeDark;
