import React from "react";
import { WEBSITE_URL } from "../utils/globals";

import VideoPlayer from "./VideoPlayer/VideoPlayer";

const videos = [
	{
		category: "Showreel",
		videos: [
			{
				iframe: `<iframe  src="https://www.youtube.com/embed/v0ZRjRKde1s?modestbranding=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
				thumbnail: "",
				title: "",
			},
			{
				iframe: `<iframe src="https://www.youtube.com/embed/eP0HrrEcwM0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
				thumbnail: "",
				title: "",
			},
			{
				iframe: `<iframe  src="https://www.youtube.com/embed/3m73-h5jr8Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
				thumbnail: "",
				title: "",
			},
		],
	},
	{
		category: "TVC's & Music",
		videos: [
			{
				iframe: `<iframe  src="https://www.youtube.com/embed/lCovELg-QIo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
				thumbnail: "",
				title: "UNESCO",
				description: "نغمة أمل... لبيروت",
			},
			{
				iframe: `<iframe src="https://www.youtube.com/embed/9wPboWggJUI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
				thumbnail: "",
				title: "SIMAH",
				description: "Simah - Brand Film",
			},
			{
				iframe: `<iframe  src="https://www.youtube.com/embed/aTcFW0wVIoE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
				thumbnail: "",
				title: "MEA",
				description: "رح يرجعو الضحكات",
			},

			{
				iframe: `<iframe  src="https://www.youtube.com/embed/QtxEW6sNEOA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
				thumbnail: "",
				title: "Mouslim - Qesmti - قسمتي",
				description: "",
			},
			{
				iframe: `<iframe src="https://www.youtube.com/embed/uU6CTJvB9sE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
				thumbnail: "",
				title: "Jordan",
				description: "destination of magic",
			},
			{
				iframe: `<iframe src="https://www.youtube.com/embed/tFNee7k7vRs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
				thumbnail: "",
				title: "Cedar Executive",
				description: "Luxury Travel Redefined",
			},

			{
				iframe: `<iframe  src="https://www.youtube.com/embed/L21Y2tGNCaA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
				thumbnail: "",
				title: "National Day - A'arda",
				description: "",
			},
			{
				iframe: `<iframe  src="https://www.youtube.com/embed/-EgJU__wUPM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
				thumbnail: "",
				title: "Ferrari Coverage",
				description: "",
			},
			{
				iframe: `<iframe  src="https://www.youtube.com/embed/bPA1740LDNU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
				thumbnail: "",
				title: "Yauatcha Riyadh",
				description: "",
			},
		],
	},

	{
		category: "Films & Documentaries",
		videos: [
			{
				iframe: `<iframe src="https://www.youtube.com/embed/-v1VR3UKpLo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
				thumbnail: "",
				title: "اعتراف",
			},
			{
				iframe: `<iframe src="https://www.youtube.com/embed/BZi1XPq_i1U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
				thumbnail: "",
				title: "TH!NKphilosophy",
			},
			{
				iframe: `<iframe src="https://www.youtube.com/embed/PjaX_TVJqWY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
				thumbnail: "",
				title: "TH!NKpod - President Kersti Kaljulaid",
			},
			{
				iframe: `<iframe src="https://www.youtube.com/embed/bzTWjAGnuuc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
				thumbnail: "",
				title: "TH!NKpod - Blockchain com",
			},
		],
	},
];

const Producer = () => {
	return (
		<div className="producer-page">
			<div className="producer-header">
				<div className="producer-header-img1">
					<img src="/assets/img/ibraheem-azar-profile.jpeg" alt="ibraheem-azar-profile" />
				</div>
				<div className="producer-header-description">
					<h3 className="producer__title">Ibrahim Azar</h3>
					<p className="producer-intro__text">
						Ibrahim ventured into production through his career in marketing and communications. He has since then produced many
						videos for prominent clients throughout the middle east. Although his work as a producer began for commercial purposes, it
						developed an artistic touch in which he sought to blend independent cinematic art into commercial TVCs.
					</p>
				</div>
			</div>

			<div className="producer-body">
				<div className="producer__projects">
					{videos.map((category, cat_index) => {
						return (
							<div key={category.category + cat_index} className="project__cateogryWrapper">
								<h4 className="category__title">{category.category}</h4>
								<div className={`cateogry__videosWrapper ${cat_index === 0 ? "cateogry__videosWrapper__showreel" : ""}`}>
									{category.videos.map((video, vid_index) => {
										return (
											<VideoPlayer
												key={video.sr + vid_index}
												iframe={video.iframe}
												thumbnail={video.thumbnail}
												title={video.title}
												description={video.description}
											/>
										);
									})}
								</div>
							</div>
						);
					})}
					{/* {videos.map((video, index) => {
					})} */}
				</div>
			</div>
		</div>
	);
};

export default Producer;
