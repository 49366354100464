import React from "react";

import classes from "./VideoPlayer.module.css";

const VideoPlayer = (props) => {
	return (
		<div className={classes.videoPlayer}>
			{/* <div className={classes.thumbnail}>
				<img src={props.thumbnail} alt="" />
			</div> */}
			<div className={classes.video}>
				<div dangerouslySetInnerHTML={{ __html: props.iframe }} />
			</div>
			<div className={classes.description}>
				<div className={classes.desc__title}>{props.title}</div>
				<div className={classes.desc__text}>{props.description}</div>
			</div>
		</div>
	);
};

export default VideoPlayer;
